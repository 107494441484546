import React from 'react';
import styled from 'styled-components';
import { List, ListItem } from '@latitude/list';
import { Text } from '@latitude/text';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';

const StyledText = styled(Text)`
  color: ${COLOR.BLACK};
`;

const GovernmentBenefitsContent = () => {
  return (
    <div className="py-3 pl-4 pr-4">
      <StyledText marginBottom={MARGIN.M16} marginTop={MARGIN.M16}>
        To verify your government benefits income, we require one of the
        following documents:
      </StyledText>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M32}>
        <strong>Bank Statement/Online Transaction History</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Your bank, branch, account number & account name
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Two consecutive income payments, most recent income showing within
            the last 31 days
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Shows deposit amounts and dates</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Shows who made the payment, e.g. WINZ</StyledText>
        </ListItem>
      </List>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M24}>
        <strong>Work & Income/IRD Letter or Statement</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>The government agency’s name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Benefit type, amount and frequency</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Is dated within the last 31 days</StyledText>
        </ListItem>
      </List>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M32}>
        <strong>Summary of Earnings issued by IRD</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Is dated within the last 31 days</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Is for the previous 12 months</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>The government agency’s name</StyledText>
        </ListItem>
      </List>
      <br />
    </div>
  );
};

export default GovernmentBenefitsContent;
