import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';

import { Accordion } from '@latitude/accordion';
import { Heading5 } from '@latitude/heading';
import { Text } from '@latitude/text';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';

import ACCContent from './_acc-content';
import CasualWorkersContent from './_casual-workers-content';
import ContractWorkersContent from './_contract-workers-content';
import GovernmentBenefitsContent from './_governement-benefits-content';
import PermanentWorkersContent from './_permanent-workers-content';
import RentalIncomeContent from './_rental-income-content';
import SelfEmployedWorkersContent from './_self-employed-workers-content';

const ProofOfIncomeSection = () => {
  return (
    <AnalyticsLocationProvider location="Proof of income">
      <Heading5 color={COLOR.BLACK}>Proof of income</Heading5>
      <Text marginBottom={MARGIN.M48}>
        Legislation requires us to confirm your income. Below is a list of what
        documents we accept, based on the nature of your income.
      </Text>

      <Accordion
        contentBgColor={COLOR.BLUE_BABY}
        fgColor={COLOR.BLUE_DEEP}
        titleBgColor={COLOR.BLUE_SKY}
        items={[
          {
            content: <PermanentWorkersContent />,
            id: 'permanent-full-or-part-time-workers',
            title: 'Permanent full or part time workers'
          },
          {
            content: <CasualWorkersContent />,
            id: 'casual-seasonal-or-temporary-workers',
            title: 'Casual, seasonal or temporary workers'
          },
          {
            content: <ContractWorkersContent />,
            id: 'contractors-fixed-term-workers',
            title: 'Fixed term contractors'
          },
          {
            content: <SelfEmployedWorkersContent />,
            id: 'self-employed-workers',
            title: 'Self-employed, investment'
          },
          {
            content: <GovernmentBenefitsContent />,
            id: 'government-benefits',
            title: 'Government benefits'
          },
          {
            content: <ACCContent />,
            id: 'acc',
            title: 'ACC'
          },
          {
            content: <RentalIncomeContent />,
            id: 'rental-income',
            title: 'Rental income'
          }
        ]}
      />
    </AnalyticsLocationProvider>
  );
};

export default ProofOfIncomeSection;
