import React from 'react';
import Helmet from 'react-helmet';

import { Heading4 } from '@latitude/heading';
import { SecondaryHero } from '@latitude/hero';
import Section from '@latitude/section';
import { Text } from '@latitude/text';
import { ALIGN, COLOR, MARGIN } from '@latitude/core/utils/constants';

import Layout from '../../components/layout';
import ProofOfIncomeSection from './_proof-of-income-section';
import ProofOfAddressSection from './_proof-of-address-section';

const SupportingDocumentsPage = () => {
  return (
    <Layout>
      <main className="navigation-spacer">
        <Helmet>
          <title>Supporting Documents | Gem by Latitude</title>
          <meta name="description" content="Gem Finance supporting documents" />
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/supporting-documents/"
          />
        </Helmet>

        <SecondaryHero heading={<>Supporting documents</>} />

        <Section className="bg-white">
          <Heading4
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            marginTop={MARGIN.M24}
            marginBottom={MARGIN.M8}
          >
            Supporting documentation for your application
          </Heading4>
          <Text marginTop={MARGIN.M32} marginBottom={MARGIN.M24}>
            When applying for finance with us we will require documentation that
            supports the information within your application.
          </Text>

          <ProofOfIncomeSection />
          <ProofOfAddressSection />
        </Section>
      </main>
    </Layout>
  );
};

export default SupportingDocumentsPage;
