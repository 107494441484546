import React from 'react';
import styled from 'styled-components';
import { List, ListItem } from '@latitude/list';
import { Text } from '@latitude/text';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';

const StyledText = styled(Text)`
  color: ${COLOR.BLACK};
`;

const ProofOfAddressContent = () => {
  return (
    <div className="py-3 pl-4 pr-4">
      <StyledText marginBottom={MARGIN.M16} marginTop={MARGIN.M16}>
        To verify your address, we require one of the following documents that
        includes your name and address, from the last 6 months:
      </StyledText>

      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Utility bill</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Rates notice</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Bank statement or credit card statement</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Loan statement</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Home and contents insurance policy (12 months for annual policy)
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Government issued document</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Rental tenancy agreement</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Property purchase and sale agreements</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Letter from educational institutions</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>KiwiSaver statement</StyledText>
        </ListItem>
      </List>
      <br />
    </div>
  );
};

export default ProofOfAddressContent;
