import React from 'react';
import styled from 'styled-components';
import { List, ListItem } from '@latitude/list';
import { Text } from '@latitude/text';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';

const StyledText = styled(Text)`
  color: ${COLOR.BLACK};
`;

const SelfEmployedWorkersContent = () => {
  return (
    <div className="py-3 pl-4 pr-4">
      <StyledText marginBottom={MARGIN.M16} marginTop={MARGIN.M16}>
        To verify your self-employed or investment income we require one of the
        following documents:
      </StyledText>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M24}>
        <strong>Summary of Earnings issued by IRD</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Is for the previous 12 months or financial year within the last 18 months</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Your business name (if any)</StyledText>
        </ListItem>
      </List>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M24}>
        <strong>Accountant’s Letter</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Your accountant&apos;s name, address and telephone number
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Is dated within the last 31 days</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            States your Net income after tax and deductions for the last 12 months or financial year within the last 18 months
          </StyledText>
        </ListItem>
      </List>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M32}>
        <strong>Tax Return (IR3)</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>
            Is prepared by an accountant with accountant’s details included
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            End of financial year date within the last 18 months
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Includes all pages</StyledText>
        </ListItem>
      </List>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M32}>
        <strong>Full Financial Statement</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>
            Is prepared by an accountant with accountant’s details included
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Shows income period within the last 18 months</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Your full name</StyledText>
        </ListItem>
      </List>
      <br />
    </div>
  );
};

export default SelfEmployedWorkersContent;
