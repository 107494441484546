import React from 'react';
import styled from 'styled-components';
import { List, ListItem } from '@latitude/list';
import { Text } from '@latitude/text';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';

const StyledText = styled(Text)`
  color: ${COLOR.BLACK};
`;

const RentalIncomeContent = () => {
  return (
    <div className="py-3 pl-4 pr-4">
      <StyledText marginBottom={MARGIN.M16} marginTop={MARGIN.M16}>
        To verify your rental income, we require one of the following documents:
      </StyledText>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M32}>
        <strong>Bank Statement/Online Transaction History</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Your bank, branch, account number & account name
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Two months of income payments, most recent income showing within the
            last 31 days
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Tenant&apos;s address or rental agency&apos;s name corresponding to
            the deposits
          </StyledText>
        </ListItem>
      </List>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M32}>
        <strong>Real Estate Rental Statements or Receipts</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>The last 2 months of rental income</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Most recent rental statement or receipt dated within the last 31
            days
          </StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Shows the real estate agent&apos;s name, address and telephone
            number
          </StyledText>
        </ListItem>
      </List>

      <StyledText marginBottom={MARGIN.M8} marginTop={MARGIN.M24}>
        <strong>Current Rental Agreement (only for newly signed)</strong>
      </StyledText>
      <List dotColor={COLOR.BLACK} marginLeft={MARGIN.M32}>
        <ListItem>
          <StyledText>Shows your full name</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>The rental address</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Term of the rental agreement</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Gross rental income</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>Contracting parties&apos; signatures</StyledText>
        </ListItem>
        <ListItem>
          <StyledText>
            Accompanied with another rental income document if over 4 weeks old
          </StyledText>
        </ListItem>
      </List>
      <br />
    </div>
  );
};

export default RentalIncomeContent;
