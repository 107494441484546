import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';

import { Accordion } from '@latitude/accordion';
import { Heading5 } from '@latitude/heading';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';

import ProofOfAddressContent from './_proof-of-address-content';

const ProofOfAddressSection = () => {
  return (
    <AnalyticsLocationProvider location="Proof of address">
      <Heading5 color={COLOR.BLACK} marginTop={MARGIN.M32}>
        Proof of address
      </Heading5>

      <Accordion
        contentBgColor={COLOR.BLUE_BABY}
        fgColor={COLOR.BLUE_DEEP}
        titleBgColor={COLOR.BLUE_SKY}
        items={[
          {
            content: <ProofOfAddressContent />,
            id: 'proof-of-address',
            title: 'Proof of address'
          }
        ]}
      />
    </AnalyticsLocationProvider>
  );
};

export default ProofOfAddressSection;
